import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { applicationsServices } from 'api/services';
import { IResApplication } from 'api/services/applications/types';
import {
  isAccountant,
  isAppealRole,
  isAssistantBranchHead,
  isBranchHead,
  isHeadAccountant,
  isHeadRole,
  isReceptionRole,
  isReportRole,
  isSaturdayEditor,
  isSuRole,
  isUMCHRole,
  isUserRole,
  isUzakovaRole,
} from 'helpers/checkRoles';
import CREATION_TYPES from 'references/creation-types';
import Template from 'components/Template/Template';
import { MainButton } from 'components/MainButton/MainButton';
import PublicIcon from '@mui/icons-material/Public';
import EditIcon from '@mui/icons-material/Edit';
import rington from 'assets/sounds/new-application-ringtone.mp3';
import * as serviceActions from 'store/ducks/services/actions';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import './ApplicationsPage.sass';
import { NewApplicationNotice } from 'components/NewApplicationNotice/NewApplicationNotice';
import * as servicesActions from 'store/ducks/services/actions';
import { Event } from 'react-socket-io';
import vehicleTypes from 'constants/vehicleTypes';
import STATUS_TYPES from 'references/status-types';

const initialFilters = {
  serial: '',
  number: '',
  pinfl: '',
  firstName: '',
  lastName: '',
  middleName: '',
  vehicleType: undefined,
  categoryId: undefined,
  status: undefined,
  branchId: undefined,
  fromDate: '',
  toDate: '',
  pass: '',
  phone: '',
  creationType: undefined,
};

export const ApplicationsPage: React.FC = () => {
  const lang = useSelector((state: AppState) => state.i18n.key);
  const { user } = useSelector((state: AppState) => state.users);
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.ApplicationsPage
  );
  const paginationLocale = useSelector(
    (state: AppState) => state.i18n.data.components.pagination
  );
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [applications, setApplications] = useState<IResApplication[]>([]);
  const [serial, setSerial] = useState('');
  const [number, setNumber] = useState('');
  const [pinfl, setPinfl] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [vehicleType, setVehicleType] = useState<IVehicleType>();
  const [categoryId, setCategoryId] = useState<number>();
  const [status, setStatus] = useState<IStatusType>();
  const [fromDate, setFromDate] = useState('');
  const [branchId, setBranchId] = useState<number>();
  const [toDate, setToDate] = useState('');
  const [pass, setPass] = useState('');
  const [phone, setPhone] = useState('');
  const [showNotice, setShowNotice] = useState(false);
  const [applicationNoticeId, setApplicationNoticeId] = useState<number>();
  const [creationType, setCreationType] = useState<ICreationType>();
  const [filters, setFilters] = useState<{
    serial: string;
    number: string;
    pinfl: string;
    firstName: string;
    lastName: string;
    middleName: string;
    vehicleType?: IVehicleType;
    categoryId?: number;
    status?: IStatusType;
    fromDate: string;
    toDate: string;
    branchId?: number;
    phone: string;
    pass: string;
    creationType?: ICreationType;
  }>(initialFilters);
  const { categories, branches } = useSelector(
    (state: AppState) => state.services
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user?.branchId) return;
    if (isSuRole(user) || isUserRole(user) || isHeadAccountant(user) || isUzakovaRole(user)) {
      setBranchId(undefined)
      return
    }
    setBranchId(user?.branchId);
  }, [user?.branchId]);

  useEffect(() => {
    if (isSuRole(user) || isUserRole(user) || isHeadAccountant(user) || (isAccountant(user) && !user?.branchId) || isUzakovaRole(user) || (isReportRole(user) && !user?.branchId) || isHeadRole(user) || (isAssistantBranchHead(user) && !user?.branchId)) {
      getApplications();
    }
  }, [filters, page, take, user]);

  useEffect(() => {
    if (!user?.branchId || !branchId) return;
    getApplications();
  }, [filters, page, take, branches, user]);

  useEffect(() => {
    getData();
  }, []);

  const getApplications = async () => {
    const params = {
      skip: take * page,
      take,
      ...filters,
      branchId,
    };
    const res = await applicationsServices
      .getApplications(params)
      .then((res) => res.data);
    setTotalCount(res.count);
    setApplications(res.data);
  };

  const getData = () => {
    if (!branches.length) dispatch(serviceActions.getBranches());
    if (!categories.length) dispatch(serviceActions.getCategories());
  };

  const checkRoles = () => {
    return (
      isReceptionRole(user) ||
      isUserRole(user) ||
      isUMCHRole(user) ||
      isReportRole(user) ||
      isAppealRole(user) ||
      isSuRole(user) ||
      isUzakovaRole(user) ||
      isBranchHead(user) ||
      isAssistantBranchHead(user) ||
      isAccountant(user) ||
      isHeadAccountant(user) ||
      isSaturdayEditor(user) ||
      isHeadRole(user)
    );
  };

  const search = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPage(0);
    setFilters({
      serial,
      number,
      pinfl,
      firstName,
      lastName,
      middleName,
      vehicleType,
      categoryId: categoryId,
      status,
      fromDate,
      toDate,
      phone,
      branchId,
      pass,
      creationType,
    });
  };

  const clearHandler = () => {
    setFilters(initialFilters);
    setPage(0);
    setSerial('');
    setNumber('');
    setPinfl('');
    setFirstName('');
    setLastName('');
    setMiddleName('');
    setVehicleType(undefined);
    setCategoryId(undefined);
    setFromDate('');
    setToDate('');
    setBranchId(undefined);
    setStatus(undefined);
    setPass('');
    setPhone('');
    setCreationType(undefined);
    getApplications();
  };

  const subscribeNewApplication = (id: number) => {
    new Audio(rington).play();
    setShowNotice(true);
    setApplicationNoticeId(id);
  };
  const closeApplicationNotice = () => {
    setShowNotice(false);
    setApplicationNoticeId(undefined);
  };

  return (
    <Template>
      {(isSuRole(user) ||
        isReceptionRole(user)) && (
        <>
          <Event
            handler={subscribeNewApplication}
            event={`new_application_${user?.branchId}`}
          />
          <Event
            handler={subscribeNewApplication}
            event={`update_application_${user?.branchId}`}
          />
          {showNotice && (
            <NewApplicationNotice
              applicationId={applicationNoticeId}
              closeApplicationNotice={closeApplicationNotice}
            />
          )}
        </>
      )}
      <div className='applications-page'>
        <Grid container className='applications-page__header'>
          <Grid xs={6} item>
            <Typography variant='h3'>{i18n.application}</Typography>
          </Grid>
          <Grid xs={6} item>
            {checkRoles() && (
              <MainButton
                link={
                  isUserRole(user)
                    ? '/applications/create/user'
                    : '/applications/create'
                }
              />
            )}
          </Grid>
        </Grid>
        {!isUserRole(user) && (
          <form className='users-page__form' onSubmit={search}>
            <Grid className='row' container spacing={2} alignItems='flex-end'>
              <Grid item md={2.4} xs={12}>
                <TextField
                  value={serial}
                  onChange={(e) => setSerial(e.target.value)}
                  label={i18n.serial}
                  autoComplete='off'
                  variant='outlined'
                  size='small'
                />
              </Grid>

              <Grid item md={2.4} xs={12}>
                <TextField
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  label={i18n.number}
                  autoComplete='off'
                  variant='outlined'
                  size='small'
                  type='number'
                />
              </Grid>
              <Grid item md={2.4} xs={12}>
                <TextField
                  value={pinfl}
                  onChange={(e) => setPinfl(e.target.value)}
                  label={i18n.pinfl}
                  autoComplete='off'
                  inputProps={{ maxLength: 14 }}
                  variant='outlined'
                  size='small'
                />
              </Grid>
              <Grid item md={2.4} xs={12}>
                <TextField
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  label={i18n.createdAt}
                  autoComplete='off'
                  variant='outlined'
                  size='small'
                  InputLabelProps={{ shrink: true }}
                  type='date'
                />
              </Grid>
              <Grid item md={2.4} xs={12}>
                <TextField
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  label={i18n.endDate}
                  autoComplete='off'
                  variant='outlined'
                  size='small'
                  type='date'
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item md={2.4} xs={12}>
                <TextField
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  label={i18n.firstName}
                  autoComplete='off'
                  variant='outlined'
                  size='small'
                />
              </Grid>
              <Grid item md={2.4} xs={12}>
                <TextField
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  label={i18n.lastName}
                  autoComplete='off'
                  variant='outlined'
                  size='small'
                />
              </Grid>
              <Grid item md={2.4} xs={12}>
                <TextField
                  value={middleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                  label={i18n.middleName}
                  autoComplete='off'
                  variant='outlined'
                  size='small'
                />
              </Grid>
              <Grid item md={2.4} xs={12}>
                <FormControl fullWidth className='select-small'>
                  <InputLabel variant='outlined' id='categoryId'>
                    {i18n.categories}
                  </InputLabel>
                  <Select
                    labelId='categoryId'
                    label={i18n.categories}
                    onChange={(e) => setCategoryId(e.target.value as number)}
                    value={categoryId || ''}
                    size='small'
                  >
                    {categories.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i[`title${lang}`]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={2.4} xs={12}>
                <FormControl fullWidth className='select-small'>
                  <InputLabel variant='outlined' id='vehicleType'>
                    {i18n.vehicleTypes}
                  </InputLabel>
                  <Select
                    labelId='vehicleType'
                    label={i18n.vehicleTypes}
                    onChange={(e) =>
                      setVehicleType(e.target.value as IVehicleType)
                    }
                    value={vehicleType || ''}
                    size='small'
                  >
                    {vehicleTypes.map((i) => (
                      <MenuItem key={i} value={i}>
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={2.4} xs={12}>
                <FormControl fullWidth className='select-small'>
                  <InputLabel variant='outlined' id='statusId'>
                    {i18n.statuses}
                  </InputLabel>
                  <Select
                    labelId='statusId'
                    label={i18n.statuses}
                    onChange={(e) => setStatus(e.target.value as IStatusType)}
                    value={status || ''}
                    size='small'
                  >
                    {STATUS_TYPES.map((i) => (
                      <MenuItem key={i.value} value={i.value}>
                        {i.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={2.4} xs={12}>
                <TextField
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  label={i18n.phone}
                  autoComplete='off'
                  variant='outlined'
                  size='small'
                  type='number'
                />
              </Grid>
              <Grid item md={2.4} xs={12}>
                <TextField
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                  label={i18n.passCode}
                  inputProps={{ maxLength: 9 }}
                  autoComplete='off'
                  variant='outlined'
                  size='small'
                />
              </Grid>
              <Grid item md={2.4} xs={12}>
                <FormControl fullWidth className='select-small'>
                  <InputLabel variant='outlined' id='creationType'>
                    {i18n.creationType}
                  </InputLabel>
                  <Select
                    labelId='creationType'
                    label={i18n.creationType}
                    onChange={(e) =>
                      setCreationType(e.target.value as ICreationType)
                    }
                    value={creationType || ''}
                    size='small'
                  >
                    {CREATION_TYPES.map((i) => (
                      <MenuItem key={i.value} value={i.value}>
                        {i.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={2.4} xs={12}>
                {
                  <FormControl fullWidth className='select-small'>
                    <InputLabel variant='outlined' id='creationType'>
                      {i18n.branch}
                    </InputLabel>
                    <Select
                      labelId='branch'
                      label={i18n.branch}
                      onChange={(e) => setBranchId(Number(e.target.value))}
                      value={branchId || ''}
                      size='small'
                    >
                      {branches?.map((i) => (
                        <MenuItem key={i.id} value={i.id}>
                          {i[`name${lang}`]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
              </Grid>
              <Grid item md={1.2} xs={3} style={{ marginLeft: 'auto' }}>
                <Button
                  variant='contained'
                  color='success'
                  type='submit'
                  className='users-page__button'
                >
                  {i18n.search}
                </Button>
              </Grid>
              <Grid item md={1.2} xs={3}>
                <Button
                  variant='contained'
                  onClick={clearHandler}
                  color='secondary'
                  className='users-page__button'
                >
                  {i18n.clear}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>{'WEB'}</TableCell>
                {isSuRole(user) && <TableCell>ID</TableCell>}
                <TableCell>{i18n.fio}</TableCell>
                <TableCell>{i18n.phone}</TableCell>
                {/* <TableCell>{i18n.appCode}</TableCell> */}
                <TableCell>{i18n.passCode}</TableCell>
                <TableCell>{'Филиал'}</TableCell>
                <TableCell>{i18n.status}</TableCell>
                <TableCell>{i18n.category}</TableCell>
                <TableCell>{i18n.transportType}</TableCell>
                {!isUserRole(user) && <TableCell>{i18n.operator}</TableCell>}
                <TableCell>{i18n.createdAt}</TableCell>
                <TableCell>{i18n.endDate}</TableCell>
                {!isUserRole(user) && (
                  <TableCell>Заблокирован</TableCell>
                )}
                {checkRoles() && (
                  <TableCell className='last-column'>{i18n.actions}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {applications.map((i, index) => (
                <TableRow
                  key={i.id}
                  className={i.user?.blackList ? 'is-block-user' : ''}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {i.creationType === 'USER' && (
                      <PublicIcon className='applications-page__word-icon' />
                    )}
                  </TableCell>
                  {isSuRole(user) && <TableCell>{i.id}</TableCell>}
                  <TableCell>
                    {`${i.user.lastName || ''} ${i.user.firstName || ''} ${
                      i.user.middleName || ''
                    }`}
                  </TableCell>
                  <TableCell>
                    {i.user.phone ? '+' + i.user.phone : ''}
                  </TableCell>
                  {/* <TableCell>{i.code}</TableCell> */}
                  <TableCell>{i?.pass}</TableCell>
                  <TableCell>{i?.branch?.nameRu}</TableCell>
                  <TableCell
                    className={`applications-page__item applications-page__item--${i.status}`}
                  >
                    {STATUS_TYPES.find((ii) => ii.value === i.status)?.label}
                  </TableCell>
                  <TableCell>{i.category[`title${lang}`]}</TableCell>
                  <TableCell>{i?.vehicleType}</TableCell>
                  {!isUserRole(user) && (
                    <TableCell>
                      {i?.operator?.lastName} {i?.operator?.firstName}{' '}
                      {i?.operator?.middleName}{' '}
                    </TableCell>
                  )}
                  <TableCell>
                    {moment(i.createdAt).format('DD.MM.YYYY HH:mm')}
                  </TableCell>
                  <TableCell>
                    {i.completedAt &&
                      moment(i.completedAt).format('DD.MM.YYYY')}
                  </TableCell>
                  {!isUserRole(user) && (
                    <TableCell>{i.user?.blackList ? 'Да' : null}</TableCell>
                  )}
                  {checkRoles() && (
                    <TableCell className='last-column'>
                      <Grid alignItems='center'>
                        <Link
                          to={`/applications/${i.id}`}
                          style={{ display: 'inline-block' }}
                        >
                          <EditIcon sx={{ fontSize: 25, color: '#003956' }} />
                        </Link>
                      </Grid>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <TablePagination
          count={totalCount}
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          rowsPerPage={take}
          labelRowsPerPage={paginationLocale.rowsPerPage}
          page={page}
          onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
          onPageChange={(e, newPage) => setPage(newPage)}
        />
      </div>
    </Template>
  );
};
